$(function () {

  // Podmiany aria-hidden w nagłówkach
  if ($('[data-title-desktop-hidden]').length > 0 && $(window).width() < 768) {
    $('[data-title-desktop-hidden]').attr('aria-hidden', 'false');
    $('[data-title-mobile-hidden]').attr('aria-hidden', 'true');
  }

  //Dynamiczne wysokości listy i mapy w go map 
  if ($("[data-go-map-places]").length > 0) {
    // css dynamic list and map height adjust
    let mapTitle = document.querySelector("[data-gomap-title-height]");
    let mapTitleHeight = mapTitle.offsetHeight;
    $(".wrapperMapList").css({
      height: "calc(100% - " + mapTitleHeight + "px)",
    });
    if ($(window).width() < 768) {
      $(".wrapperGoogleMap").css({
        top: mapTitleHeight + "px",
      });
    }
  }
  // Wyliczanie wysokosci boxScroll w mapach z go
  // if ($("[data-go-map-places]").length > 0) {
  // const listH = $("[data-go-map-places]").find(".wrapperMapList").height();
  // const listTitleH = $("[data-go-map-places-title]").outerHeight();
  //
  // $("[data-go-map-places-boxscroll]").css("height", listH - listTitleH);
  // }

  //acordion show hide text
  if ($(".acordion").length > 0) {
    // działanie zwijanie i rozwijanie
    $("[data-toggle-acordion-text]").on("click", function () {
      // console.log("test");

      let acordionClickedItem = $(this).parent();
      let acordionText = acordionClickedItem.find("[data-acordion-text]");
      let acordionIcon = acordionClickedItem.find("[data-acordion-icon]");
      let acordionButton = acordionClickedItem.find("button");

      if (acordionText.hasClass("alreadyVisible")) {
        $(acordionText).stop().slideUp("slow");
        acordionText.removeClass("alreadyVisible");
        acordionIcon.removeClass("active");
        acordionButton.attr("aria-expanded", "falce");
        return;
      }
      $(acordionText).stop().slideDown("slow");
      acordionIcon.addClass("active");
      acordionText.addClass("alreadyVisible");
      acordionButton.attr("aria-expanded", "true");
    });
  }

  // Box go one event refactor code
  if ($(".goOneEvent").length > 0) {
    $(".goEventWrapper").each(function () {
      $(this)
        .removeClass("clearfix")
        .children()
        .not(":first-child")
        .wrapAll('<div class="goEventMainContent" />');
    });
  }

  // progressbar
  if ($('.round').length > 0) {
    $('.round').each(function () {
      let $round = $(this),
        roundRadius = $round.find('circle').attr('r'),
        roundPercent = $round.data('percent'),
        roundCircum = 2 * roundRadius * Math.PI,
        roundDraw = roundPercent * roundCircum / 100;

      switch (roundPercent) {
        case 100:
          $round.removeClass('none');
          $round.addClass('full');
          $round.css('stroke-dasharray', roundDraw + ' 999');
          break
        case 0:
          $round.addClass('none');
          break
        default:
          $round.removeClass('none');
          $round.css('stroke-dasharray', roundDraw + ' 999');
      }
    });
  }

  function scrollToPage(el, space, time) {
    $('html, body').animate({
      scrollTop: el.offset().top - space
    }, time);
  }
});